import './Imprint.css'

function Imprint() {
    return (
        <div className='imprint'>
            <h1>Impressum</h1>
            <h2 id="m46">Diensteanbieter</h2>
            <p>Maximilian Gabler</p>
            <p>Steinfeldstraße 5a</p>
            <p>82279 Eching am Ammersee</p>

            <h2 id="m56">Kontaktmöglichkeiten</h2><p>E-Mail-Adresse: <a href="mailto:gabler.max@web.de">gabler.max@web.de</a></p>

            <h2 id="m5234">Vorbehalt der Nutzung für Text und Data Mining</h2><p>Vorbehalt der Nutzung für Text und Data Mining: Der Inhaber dieser Website gestattet die Nutzung oder das Herunterladen von Inhalten dieser Website durch Dritte für die Entwicklung, das Training oder den Betrieb von künstlicher Intelligenz oder anderen maschinellen Lernsystemen ("Text und Data Mining") ausschließlich mit ausdrücklicher schriftlicher Zustimmung des Inhabers. Ohne eine solche Zustimmung ist es untersagt, die Inhalte für Text und Data Mining zu verwenden. Dies gilt auch, wenn auf der Website keine Meta-Angaben vorhanden sind, die entsprechende Verfahren aussperren, und selbst dann, wenn Bots, die den Zweck haben, die Website zu Zwecken des Text und Data Mining auszulesen, nicht ausgesperrt werden.</p>

            <p className="seal"><a href="https://datenschutz-generator.de/" title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p>
        </div>
    )
}

export default Imprint