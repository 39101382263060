import React, { createRef, useRef, useState } from 'react';
import bg from "./design2.png"
import './App.css';
import { text } from './i18';
import getConfig from './config';

const CONFIG = getConfig()
const FOLDER_ID_KEY: string = "id"

function App() {
	let [disabled, setDisabled] = useState(false)
	let [name, setName] = useState("");
	let [viewName, setViewName] = useState("");
	let [nameValidation, setNameValidation] = useState("")
	let [files, setFiles] = useState(null as FileList | null)
	let [fileValidation, setFileValidation] = useState("")
	let [summaryValidation, setSummaryValidation] = useState("")
	let [success, setSuccess] = useState(false)
	let fileRef = useRef(null as HTMLInputElement | null)

	let nameValidationStarted = false
	let fileValidationStarted = false

	function validateAll(name: string, files: FileList | null): boolean {
		if (success) {
			setSummaryValidation("")
			setSuccess(false)
		}
		let folderIdValidationResult = getFolderId()
		if (!validateFolderId(folderIdValidationResult)) {
			setSummaryValidation(text("SUMMARY_INVALID_ID_VALIDATION"))
			setDisabled(true)
			return false
		}

		let fileValidationResult = (fileValidationStarted) ? validateFiles(files) : true
		let nameValidationResult = (nameValidationStarted) ? validateName(name) : true

		if (!fileValidationResult || !nameValidationResult) {
			if (nameValidationStarted && fileValidationStarted) {
				setSummaryValidation(text("SUMMARY_INVALID_VALIDATION"))
			}
			return false
		}
		setSummaryValidation("")
		return true
	}
	
	function validateFolderId(id: string | null): boolean {
		return id !== null && id.length > 20 && !id.includes(" ")
	}

	function validateName(name: string): boolean {
		const nameParts = (name.indexOf(" ") !== -1) ? name.split(" ") : [name]
		const namePattern = /^[a-zA-Z0-9]+$/
		if (nameParts.length === 0) {
			setNameValidation(text("NAME_INVALID_EMPTY_VALIDATION"))
			return false
		}

		for (const namePart of nameParts) {
			if (namePart.length === 0) {
				setNameValidation(text("NAME_INVALID_EMPTY_VALIDATION"))
				return false
			}
			if (!namePattern.test(namePart)) {
				setNameValidation(namePart + text("NAME_INVALID_VALIDATION"))
				return false
			}
		}
		setNameValidation("")
		return true
	}

	function validateFiles(files: FileList | null): boolean {
		if (files === null || files.length === 0) {
			setFileValidation(text("FILES_INVALID_VALIDATION"))
			return false
		}
		setFileValidation("")
		return true
	}

	function getFolderId(): string | null {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		return urlParams.get(FOLDER_ID_KEY)
	}

	async function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
		event.preventDefault();
		setSuccess(false)
		setDisabled(true)
		
		nameValidationStarted = true
		fileValidationStarted = true

		if (!validateAll(name, files)) {
			setDisabled(false)
			return
		}

		console.log("Uploading files...")
        const formData = new FormData();
        formData.append("name", name);
		formData.append("folder_id", getFolderId()!);
        for(let i = 0; i < files!.length; i++) {
            formData.append("files", files![i]);
        }
		try {
			const res = await fetch(CONFIG.API_URL + "/upload", {
				method: 'POST',
				body: formData,
			})
			const data = await res.json()
			if (!data.success) {
				throw new Error(data.message)
			}
			console.log("Successfully uploaded files")
			setSummaryValidation(text("SUMMARY_REQUEST_SUCCEEDED"))
			setSuccess(true)
			setName("")
			setViewName("")
			setFiles(null)
			fileRef.current!.value = ""
			nameValidationStarted = false
			fileValidationStarted = false
		} catch (err) {
			setSummaryValidation(text("SUMMARY_REQUEST_FAILED_VALIDATION"))
		} finally {
			setDisabled(false)
		}
        
	}

	function onFileChange(e: React.SyntheticEvent<HTMLInputElement>) {
		let files = (e.target as HTMLInputElement).files
		fileValidationStarted = true
		setFiles(files)
		validateAll(name, files)
	}

	function onNameInputChange(e: React.SyntheticEvent<HTMLInputElement>) {
		let name = (e.target as HTMLInputElement).value
		nameValidationStarted = true
		setViewName(name)
		name = name.trim()
		setName(name)
		validateAll(name, files)
	}

	return (
		<div className="container" onLoad={() => validateAll(name, files)}>
			<div className='top-spacer'></div>
			<div className='inner-container'>
				<div className='header-container'>
					<h1>Veronika & Christopher</h1>
					<h2>{text("HEADER_LABEL")}</h2>
				</div>
				<form id='upload' onSubmit={handleSubmit} aria-disabled={disabled}>
					<div className="input-group">
						<label htmlFor='name'>{text("NAME_LABEL")}</label>
						<input name='name' id='name' placeholder={text("NAME_PLACEHOLDER")} value={viewName} onChange={onNameInputChange} disabled={disabled}/>
						<label className="error-text info-text" htmlFor='name-validation'>{nameValidation}</label>
					</div>
					<div className="input-group">
						<label htmlFor='files'>{text("FILES_LABEL")}</label>
						<input id='files' type="file" ref={fileRef} accept="image/*,.heif" onChange={onFileChange} multiple disabled={disabled}/>
						<label className="error-text info-text" htmlFor='file-validation'>{fileValidation}</label>
					</div>
					<button className="submit-btn" type='submit' disabled={disabled}><div className='upload-label'>{text("UPLOAD_LABEL")}</div><div className={"loader" + ((disabled) ? " loading" : "") } id="loader"></div></button>
					<label className={'error-text info-text' + ((success) ? " success": "")}>{summaryValidation}</label>
				</form>
			</div>
			<div className='footer'>
				<div>
					<a className='footer-item' href='/imprint'>{text("IMPRINT_LABEL")}</a>
					<a className='footer-item' href='/data-protection'>{text("DATA_PROTECTION_LABEL")}</a>
					<a className='footer-item' href="https://www.flaticon.com/de/kostenlose-icons/hochzeit" title="hochzeit Icons">Favicon erstellt von Freepik - Flaticon</a>	
				</div>
			</div>
			<img className='top-img' src={bg}></img>
			<img className='bottom-img' src={bg}></img>
		</div>
	);
}

export default App;
