import i18 from "./i18.json"

const LANGUAGE_KEY: string = navigator.language

export function text(key: string): string {
    let langKey: string = "default"
    if (i18.hasOwnProperty(LANGUAGE_KEY)) {
        langKey = LANGUAGE_KEY
    }
    let lang = (i18 as any)[langKey]
    if (!lang.hasOwnProperty(key)) {
        throw new Error("I18 text for current language not found")
    }
    return lang[key]
}